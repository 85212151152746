import React from 'react'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

export default function NotFound() {
    return (
        <Layout>
        <SEO title={'Not Found'} />
        <p>You found my secret collection. eventually i'll add images here. </p>
        </Layout>
    )
}
